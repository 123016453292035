import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {PagesArchitecture} from "../../constants/page/pagesArchitecture"
import {Page} from "../../constants/page/pages"
import {
    bypassLogin,
    connectedUserInfo,
    disconnectUser,
} from "../../shared-components/actions/sessionStorage/user/userActions"
import {OrderActions, Order} from "../../constants/orderActionsEnum"
import {
    getInformation,
    getBeneficiaryPreOrderInformationsFromSession,
} from "../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActions"
import {getBeneficiaryContracts} from "../../shared-components/actions/temporaries/beneficiary/beneficiaryActions"
import BypassLogin from "../../shared-components/components/BypassLogin/bypassLogin"
import {saveDynamicConfiguration} from "../../shared-components/actions/localStorage/dynamicConfiguration/dynamicConfigurationActions"
import {DYN_CONF_KEY_CONTRACT_ID} from "../../shared-components/constants/dynamicConfigurationKeys"
import {getPreOrderSessionContractId} from "../../store/selectors/localStorage/preOrderSessionSelectors"
import {editOrderType} from "../../actions/localStorage/orderType/orderTypeActions"
import {editContent} from "../../actions/localStorage/orderContent/orderContentActions"
import {editPayment} from "../../actions/localStorage/orderPayment/orderPaymentActions"
import {editDelivery} from "../../actions/localStorage/orderDelivery/orderDeliveryActions"
import {addNotification} from "../../shared-components/actions/notifications/notificationsActions"
import {getBeneficiaryEnabledFeatures} from "../../shared-components/actions/localStorage/features/featuresActions"
import uuidv4 from "uuid"
import {changeActualPage} from "../../shared-components/actions/temporaries/page/pageActions"
import {BENEFICIARY_AREA} from "../../shared-components/constants/areaTypes"

class BypassLoginSceneContainer extends Component {
    componentDidMount() {
        // Get search and remove '?'
        const search = this.props.location.search.substring(1)
        // Convert the string into an object for parsing
        const params = new URLSearchParams(search)

        // Read expected params
        const sourceId = params.get("sourceId")
        const userLogin = params.get("userLogin")
        const bypassToken = params.get("bypassToken")
        const preOrderSessionId = params.get("preOrderSessionId")
        const actionType = params.get("actionType")
        const fromBo = params.get("fromBo")
        const readOnly = params.get("readOnly")

        // Mandatory params for these two cases
        const mandatoryFromBoOrReadOnlyParams = !sourceId || !userLogin || !bypassToken
        const mandatoryOrderParams = !sourceId || !userLogin || !bypassToken || !preOrderSessionId || !actionType

        // If one param is missing then redirect to login
        if (
            ((fromBo || readOnly) && mandatoryFromBoOrReadOnlyParams) ||
            (!fromBo && !readOnly && mandatoryOrderParams)
        ) {
            this.redirectToLogin()
            // End the function, otherwise it continue and call the API with null params
            return
        }

        // Disconnecting user in case of user already connected
        this.props.disconnectUser()

        if (fromBo || readOnly) {
            // Update actual page
            this.props.changeActualPage()

            // Call api to bypass login
            this.props.bypassLogin(sourceId, userLogin, bypassToken, null).then(() => {
                // Retrieve user info
                this.props.connectedUserInfo().then(() => {
                    this.props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route)
                })
            })
        } else {
            // Update actual page
            this.props.changeActualPage()

            // Call api to bypass login
            this.props.bypassLogin(sourceId, userLogin, bypassToken, preOrderSessionId).then(
                () => {
                    // Retrieve user info
                    this.props.connectedUserInfo().then(() => {
                        // Retrieve beneficiary contracts
                        this.props.getBeneficiaryContracts().then(() => {
                            // Save the preOrderSessionId from the url in local storage
                            this.props
                                .getSessionInformations(preOrderSessionId)
                                .then(({displayIntroductionMessage, introductionMessage}) => {
                                    if (actionType === Order.NEW_ORDER) {
                                        this.props
                                            .getBeneficiaryPreOrderInformationsFromSession(preOrderSessionId)
                                            .then(
                                                payload => {
                                                    // Save preOrderSession contract id in local storage for customization
                                                    this.props.saveDynamicConfiguration(this.props.contractId + "")
                                                    // Fetch enabled features
                                                    this.props.getBeneficiaryEnabledFeatures(this.props.contractId)
                                                    if (payload.preOrderAmounts.totalAmount > 0) {
                                                        // This value is used by a select list and we need it to be "String" type
                                                        if (payload.preOrderDelivery.deliveryPointId) {
                                                            payload.preOrderDelivery.deliveryPointId =
                                                                payload.preOrderDelivery.deliveryPointId.toString()
                                                        }
                                                        // Save the existing pre order in localstorage
                                                        this.props.saveExistingPreOrder(payload)
                                                        this.props.editOrderType(Order.EDIT_ORDER)
                                                    } else {
                                                        this.props.editOrderType(Order.NEW_ORDER)
                                                    }

                                                    // Redirect to introduction page if there is an introduction message
                                                    if (displayIntroductionMessage && introductionMessage) {
                                                        this.props.history.push(
                                                            PagesArchitecture[Page.ORDER_INFORMATION].route,
                                                        )
                                                    } else {
                                                        this.props.history.push(
                                                            PagesArchitecture[Page.ORDER_PROFILE].route,
                                                        )
                                                    }
                                                },
                                                error => {
                                                    this.redirectAndDisplayError(error)
                                                },
                                            )
                                    } else {
                                        // Redirect user to the home page
                                        this.props.history.push(
                                            PagesArchitecture[OrderActions[actionType].redirectionPage].route,
                                        )
                                    }
                                })
                        })
                    })
                },
                error => {
                    this.redirectAndDisplayError(error)
                },
                () => {
                    // If bypass login fail, redirect to login
                    this.redirectToLogin()
                },
            )
        }
    }

    redirectAndDisplayError = error => {
        this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
        this.props.addNotification({
            id: uuidv4(),
            type: "error",
            header: "Erreur",
            content: error,
            autoDismiss: false,
        })
    }

    redirectToLogin = () => {
        this.props.history.push(PagesArchitecture[Page.LOGIN].route)
    }

    render() {
        return <BypassLogin />
    }
}

const mapDispatchToProps = dispatch => ({
    addNotification: notification => dispatch(addNotification(notification)),
    bypassLogin: (sourceId, userLogin, bypassToken, preOrderSessionId) => {
        return dispatch(bypassLogin(sourceId, userLogin, bypassToken, BENEFICIARY_AREA, preOrderSessionId))
    },
    changeActualPage: () => dispatch(changeActualPage(Page.BYPASS_LOGIN)),
    connectedUserInfo: () => dispatch(connectedUserInfo()),
    disconnectUser: () => dispatch(disconnectUser()),
    editOrderType: orderType => dispatch(editOrderType(orderType)),
    getBeneficiaryEnabledFeatures: contractId => dispatch(getBeneficiaryEnabledFeatures(contractId)),
    getBeneficiaryPreOrderInformationsFromSession: sessionId =>
        dispatch(getBeneficiaryPreOrderInformationsFromSession(sessionId)),
    getBeneficiaryContracts: () => dispatch(getBeneficiaryContracts()),
    getSessionInformations: sessionId => dispatch(getInformation(sessionId)),
    saveDynamicConfiguration: contractId => dispatch(saveDynamicConfiguration(DYN_CONF_KEY_CONTRACT_ID, contractId)),
    saveExistingPreOrder: payload => {
        dispatch(editContent(payload.preOrderAmounts))
        dispatch(editPayment(payload.preOrderPayment))
        dispatch(editDelivery(payload.preOrderDelivery))
    },
})

export default withRouter(
    connect(
        state => ({
            contractId: getPreOrderSessionContractId(state),
        }),
        mapDispatchToProps,
    )(BypassLoginSceneContainer),
)
