import PropTypes from "prop-types"
import React from "react"
import {Button as SemanticButton, Icon, Loader} from "semantic-ui-react"
import "./buttonTertiary.scss"

const ButtonTertiary = ({children, actionType, className, disabled, loading, icon, withoutPadding, ...props}) => {
    return (
        <div
            className={
                "button-tertiary" + (disabled || loading ? " disabled" : "") + (withoutPadding ? " no-padding" : "")
            }
        >
            {loading ? (
                <Loader active={true} inline size="tiny" />
            ) : (
                !!icon && <Icon className={icon} disabled={disabled} {...props} />
            )}
            <SemanticButton
                className={className ? className : ""}
                disabled={disabled || loading}
                {...props}
                type={actionType}
            >
                {children}
            </SemanticButton>
        </div>
    )
}
ButtonTertiary.defaultProps = {
    actionType: undefined,
    className: null,
    disabled: false,
    loading: false,
    onClick: null,
}

ButtonTertiary.propTypes = {
    actionType: PropTypes.oneOf(["button", "reset", "submit"]),
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    withoutPadding: PropTypes.bool,
}

ButtonTertiary.defaultProps = {
    disabled: false,
    icon: null,
    loading: false,
    withoutPadding: false,
}

export default ButtonTertiary
