/* eslint-disable react/no-multi-comp */
import React, {Suspense} from "react"
import {Redirect, Route, Router, Switch} from "react-router-dom"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import history from "../history"
import BypassLoginSceneContainer from "../scenes/BypassLogin/bypassLoginContainer"
import LoginSceneContainer from "../scenes/Login/loginContainer"
import ContractConfigurationContainer from "../shared-components/components/ContractConfiguration/contractConfigurationContainer"
import NotConnectedRoute from "../shared-components/components/NotConnectedRoute/notConnectedRoute"
import PermissionRoute from "../shared-components/components/PermissionRoute/permissionRoute"
import {getDynamicConfigurationValue} from "../store/selectors/localStorage/dynamicConfigurationSelectors"
import {getEnabledFeatures} from "../store/selectors/localStorage/featuresSelectors"
import {getStyle} from "../store/selectors/localStorage/styleSelectors"
import {getTexts} from "../store/selectors/localStorage/textSelector"
import {updateGlobalTexts} from "../styles/globalText"
import {
    getUserPermissions,
    getUserToken,
    getUserClientType,
    getExpirationDate,
    isUserConnected,
} from "../store/selectors/sessionStorage/userSelectors"
import {
    AsyncLegalNoticeScene,
    AsyncNotFoundScene,
    AsyncOrderLayout,
    AsyncOrderInformationScene,
    AsyncViewOrderScene,
    AsyncUnauthorizedScene,
    AsyncRecoverLoginScene,
    AsyncResetPasswordScene,
    AsyncUserProfileLayout,
    AsyncAccountPaymentHistoryScene,
    AsyncTaxDocumentScene,
    AsyncOrderSessionListScene,
    AsyncVisualisationChoiceScene,
    AsyncCesuConversionLayout,
    AsyncOrderHistoryScene,
    AsyncAccountStatementScene,
    AsyncAccountPaymentLayout,
    AsyncCheckPaymentLayout,
    AsyncCheckbookStatementScene,
    AsyncCheckPaymentHistoryScene,
    AsyncRegistrationLayout,
    AsyncMillesimeTradeLayout,
    AsyncDeclarationHistoryScene,
    AsyncHomeScene,
    AsyncRgpdScene,
    AsyncStyleGuideScene,
    AsyncHelpScene,
} from "./asyncScenes"

const RedirectToHome = () => (
    <Redirect
        to={{
            pathname: PagesArchitecture[Page.HOME].route,
        }}
    />
)

const CustomLoginScene = () => (
    <ContractConfigurationContainer>
        <LoginSceneContainer />
    </ContractConfigurationContainer>
)

/**
 * Les differentes routes de l'application
 */
const RoutingLayout = () => {
    const defaultRouteExternalSelectors = {
        getStyle,
        getTexts,
        getDynamicConfigurationValue,
        getEnabledFeatures,
        getExpirationDate,
        updateGlobalTexts,
    }

    return (
        <Router history={history}>
            <Suspense fallback={<div />}>
                <Switch>
                    {/* Acces avec bypass */}
                    <Route
                        component={BypassLoginSceneContainer}
                        exact
                        path={PagesArchitecture[Page.BYPASS_LOGIN].route}
                    />
                    {/* Recharge la personnalisation si un numéro de contrat est spécifié */}
                    <NotConnectedRoute
                        component={CustomLoginScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        isUserConnected={isUserConnected}
                        path={PagesArchitecture[Page.LOGIN].routeWithParamDesc}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Page de connexion */}
                    <NotConnectedRoute
                        component={LoginSceneContainer}
                        connectedUserRoute={PagesArchitecture[Page.VISUALISATION_CHOICE].route} // L'utilisateur est automatiquement redirigé vers cette page si le token est défini dans le store
                        exact
                        isUserConnected={isUserConnected}
                        path={PagesArchitecture[Page.LOGIN].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Connexion / Inscription */}
                    <NotConnectedRoute
                        component={LoginSceneContainer}
                        connectedUserRoute={PagesArchitecture[Page.VISUALISATION_CHOICE].route}
                        exact
                        isUserConnected={isUserConnected}
                        path={PagesArchitecture[Page.REGISTRATION].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Page d'accueil */}
                    <PermissionRoute
                        component={AsyncHomeScene}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.HOME}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.HOME].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Page d'aide */}
                    <PermissionRoute
                        component={AsyncHelpScene}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.HELP}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.HELP].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Inscription */}
                    <Route component={AsyncRegistrationLayout} path="/inscription" />
                    {/* Choix de visualisation */}
                    <PermissionRoute
                        component={AsyncVisualisationChoiceScene}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        isUserConnected={isUserConnected}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.VISUALISATION_CHOICE}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.VISUALISATION_CHOICE].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Redirection */}
                    <PermissionRoute
                        component={RedirectToHome}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.HOME}
                        pagesArchitecture={PagesArchitecture}
                        path="/"
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Oubli de mdp ou d'identifiant */}
                    <NotConnectedRoute
                        component={AsyncRecoverLoginScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        isUserConnected={isUserConnected}
                        path={PagesArchitecture[Page.RECOVER_LOGIN_PASSWORD].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Changement de mot de passe */}
                    <NotConnectedRoute
                        component={AsyncResetPasswordScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        isUserConnected={isUserConnected}
                        path={PagesArchitecture[Page.RESET_PASSWORD].routeWithParamDesc}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Détail de la commande */}
                    <PermissionRoute
                        component={AsyncViewOrderScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.VIEW_ORDER}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.VIEW_ORDER].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Style Guide */}
                    <Route component={AsyncStyleGuideScene} exact path={PagesArchitecture[Page.STYLE_GUIDE].route} />
                    {/* Mentions légales */}
                    <Route component={AsyncLegalNoticeScene} exact path={PagesArchitecture[Page.LEGAL_NOTICE].route} />
                    {/* RGPD */}
                    <Route component={AsyncRgpdScene} exact path={PagesArchitecture[Page.RGPD].route} />
                    {/* Informations */}
                    <PermissionRoute
                        component={AsyncOrderInformationScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.ORDER_INFORMATION}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.ORDER_INFORMATION].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Commande */}
                    <Route component={AsyncOrderLayout} path="/commande" />
                    {/* Echange de millesime */}
                    <Route component={AsyncMillesimeTradeLayout} path="/echange" />
                    {/* Profil */}
                    <Route component={AsyncUserProfileLayout} path="/profil" />
                    {/* Conversion de CESU */}
                    <Route component={AsyncCesuConversionLayout} path="/changer-format" />
                    {/* Précommande */}
                    <PermissionRoute
                        component={AsyncOrderSessionListScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.ORDER_SESSION_LIST}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.ORDER_SESSION_LIST].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Historique des commandes */}
                    <PermissionRoute
                        component={AsyncOrderHistoryScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.ORDER_HISTORY}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.ORDER_HISTORY].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Historique des paiements */}
                    <PermissionRoute
                        component={AsyncAccountPaymentHistoryScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.ACCOUNT_PAYMENT_HISTORY}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.ACCOUNT_PAYMENT_HISTORY].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Paiement compte */}
                    <Route component={AsyncAccountPaymentLayout} path="/paiement/compte" />
                    {/* Paiement titre */}
                    <Route component={AsyncCheckPaymentLayout} path="/paiement/titre" />
                    {/* Mon solde */}
                    <PermissionRoute
                        component={AsyncAccountStatementScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.ACCOUNT_STATEMENT}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.ACCOUNT_STATEMENT].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Mes titres */}
                    <PermissionRoute
                        component={AsyncCheckbookStatementScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.CHECKBOOK_STATEMENT}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.CHECKBOOK_STATEMENT].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Historique des paiements */}
                    <PermissionRoute
                        component={AsyncCheckPaymentHistoryScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.CHECK_PAYMENT_HISTORY}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.CHECK_PAYMENT_HISTORY].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Historique des versements */}
                    <PermissionRoute
                        component={AsyncAccountStatementScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.TRANSACTIONS_HISTORY}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.TRANSACTIONS_HISTORY].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Historique des déclarations */}
                    <PermissionRoute
                        component={AsyncDeclarationHistoryScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.DECLARATION_HISTORY}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.DECLARATION_HISTORY].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Attestations fiscales */}
                    <PermissionRoute
                        component={AsyncTaxDocumentScene}
                        connectedUserRoute={PagesArchitecture[Page.HOME].route}
                        exact
                        getUserClientType={getUserClientType}
                        getUserPermissions={getUserPermissions}
                        getUserToken={getUserToken}
                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                        page={Page.TAX_DOCUMENT}
                        pagesArchitecture={PagesArchitecture}
                        path={PagesArchitecture[Page.TAX_DOCUMENT].route}
                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                        {...defaultRouteExternalSelectors}
                    />
                    {/* Route non autorisée */}
                    <Route component={AsyncUnauthorizedScene} exact path={PagesArchitecture[Page.UNAUTHORIZED].route} />
                    {/* Route non trouvée */}
                    <Route component={AsyncNotFoundScene} path="" {...defaultRouteExternalSelectors} />
                </Switch>
            </Suspense>
        </Router>
    )
}

export default RoutingLayout
